.create-subscription {
  display: flex;
  > .payment-form {
    flex: 1;
    margin-right: 20px;
    .injected-payment-form { max-height: 440px; }
  }
  > .payment-info {
    flex: 1;
    margin-left: 20px;
  }
}

// To avoid the hassle of restructuring our React component tree, hack the CSS:
.create-subscription .payment-form {
  .simple-button, small {
    position: relative;
    left: 460px;
    top: -120px;
  }
  small {
    text-align: left;
    line-height: 150%;
    color: #a9b2c0;
    margin-top: -3px;
  }
}
