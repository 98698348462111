.page-with-navbar {
  display: grid;

  .navbar {
    display: flex;
    align-items: center;
    height: 81px;
    border-bottom: 1px solid #eff4f5;
    background: #FFF;
    background-image: url("../../logo.png");
    background-position: 31px center;
    background-repeat: no-repeat;
    background-size: 154px 29px;

    display: flex;
    justify-content: flex-end;


    button.logout-button {
      margin-right: 20px;
    }

    .settings-link, .cancel-link {
      color: grey;
      padding: 30px;
      &:hover { color: #4D74ED; }
    }

    > a {
    text-decoration: none;
    color: #909090;
  }
}

  .content {
    padding: 16px 32px;
  }
  .footer {
    // ...
  }
}
