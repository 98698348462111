.error-boundary {
  margin: 16px;
  margin-left: 0;
  margin-right: 0;
  padding: 24px;
  background-image: url('background.png');
  text-align: center;
  color: #fff;
  border-radius: 8px;
}

.error-boundary::before {
  display: block;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
  content: 'Something went wrong';
}

.error-boundary::after {
  display: block;
  margin-top: 8px;
  font-size: 11px;
  content: 'Please report this as a bug.';
}
