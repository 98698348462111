@keyframes spinner {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.spinner div {
  left: 58.9px;
  top: 32.86px;
  position: absolute;
  animation: spinner linear 0.9345794392523364s infinite;
  background: #4d74ed;
  width: 6.2px;
  height: 6.2px;
  border-radius: 3.1px / 3.1px;
  transform-origin: 3.1px 29.14px;
}.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.8722741433021807s;
  background: #4d74ed;
}.spinner div:nth-child(2) {
  transform: rotate(24deg);
  animation-delay: -0.8099688473520249s;
  background: #4d74ed;
}.spinner div:nth-child(3) {
  transform: rotate(48deg);
  animation-delay: -0.7476635514018691s;
  background: #4d74ed;
}.spinner div:nth-child(4) {
  transform: rotate(72deg);
  animation-delay: -0.6853582554517134s;
  background: #4d74ed;
}.spinner div:nth-child(5) {
  transform: rotate(96deg);
  animation-delay: -0.6230529595015576s;
  background: #4d74ed;
}.spinner div:nth-child(6) {
  transform: rotate(120deg);
  animation-delay: -0.5607476635514018s;
  background: #4d74ed;
}.spinner div:nth-child(7) {
  transform: rotate(144deg);
  animation-delay: -0.4984423676012461s;
  background: #4d74ed;
}.spinner div:nth-child(8) {
  transform: rotate(168deg);
  animation-delay: -0.43613707165109034s;
  background: #4d74ed;
}.spinner div:nth-child(9) {
  transform: rotate(192deg);
  animation-delay: -0.37383177570093457s;
  background: #4d74ed;
}.spinner div:nth-child(10) {
  transform: rotate(216deg);
  animation-delay: -0.3115264797507788s;
  background: #4d74ed;
}.spinner div:nth-child(11) {
  transform: rotate(240deg);
  animation-delay: -0.24922118380062305s;
  background: #4d74ed;
}.spinner div:nth-child(12) {
  transform: rotate(264deg);
  animation-delay: -0.18691588785046728s;
  background: #4d74ed;
}.spinner div:nth-child(13) {
  transform: rotate(288deg);
  animation-delay: -0.12461059190031153s;
  background: #4d74ed;
}.spinner div:nth-child(14) {
  transform: rotate(312deg);
  animation-delay: -0.06230529595015576s;
  background: #4d74ed;
}.spinner div:nth-child(15) {
  transform: rotate(336deg);
  animation-delay: 0s;
  background: #4d74ed;
}
.please-wait {
  width: 124px;
  height: 124px;
  display: block;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  background: none;
  position: relative;
  transform: scale(.7);
}
.spinner {
  width: 100%;
  height: 100%;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.spinner div { box-sizing: content-box; }