.compatibility-summary {
  margin-top: 22px;
  padding: 22px;
  background: #f9f9f9;
  width: calc(100% + 44px);
  margin-left: -22px;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;

  > .title {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  > .compatibility-list {
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 0;

    > dd {
      display: flex;
      margin: 0;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    > dd { padding: 10px; }
    > dt { padding: 10px 10px 10px 0; }

  }

}
