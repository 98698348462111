.compatibility-result {
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding: 25px 22px;

  > .title {
    font-size: 23px;
    font-weight: bold;
  }
  > .icons {
    text-align: right;
    max-height: 0;
    position: relative;
    top: -25px;
  }
}
