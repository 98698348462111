.hazard-label {
  display: inline-block;
  width: 130px;
  height: 130px;
  margin: 20px 0;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("missing.png");

  &.class-1 { background-image: url("images/class_1.png") } // UN3232
  &.class-1-1 { background-image: url("images/class_1_1.gif") } // UN0004
  &.class-1-2 { background-image: url("images/class_1_2.gif") } // UN0400
  &.class-1-3 { background-image: url("images/class_1_3.gif") } // UN0021
  &.class-1-4B { background-image: url("images/class_1_4B.gif") } // UN0383
  &.class-1-4C { background-image: url("images/class_1_4C.gif") } // UN0510
  &.class-1-4D { background-image: url("images/class_1_4D.gif") } // UN0494
  &.class-1-4E { background-image: url("images/class_1_4E.gif") } // UN0471
  &.class-1-4F { background-image: url("images/class_1_4F.gif") } // UN0472
  &.class-1-4G { background-image: url("images/class_1_4G.gif") } // UN0505
  &.class-1-4S { background-image: url("images/class_1_4S.gif") } // UN0500
  &.class-1-6N { background-image: url("images/class_1_6N.gif") } // UN0486
  &.class-2-1 { background-image: url("images/class_2_1.gif") } // UN1001
  &.class-2-2 { background-image: url("images/class_2_2.jpg") } // UN3538
  &.class-2-3 { background-image: url("images/class_2_3.gif") } // UN3539
  &.class-3 { background-image: url("images/class_3.jpg") } // UN1051
  &.class-4-1 { background-image: url("images/class_4_1.gif") } // UN2000
  &.class-4-2 { background-image: url("images/class_4_2.gif") } // UN3542
  &.class-4-3 { background-image: url("images/class_4_3.gif") } // UN2830
  &.class-5-1 { background-image: url("images/class_5_1.gif") } // UN3544
  &.class-5-2 { background-image: url("images/class_5_2.gif") } // UN3545
  &.class-6-1 { background-image: url("images/class_6_1.gif") } // UN1051
  &.class-6-2 { background-image: url("images/class_6_2.gif") } // UN3373
  &.class-7_X { background-image: url("images/class_7_none.png") } // UN2977
  &.class-7_I { background-image: url("images/class_7_I.gif") } // UN3507
  &.class-7_II { background-image: url("images/class_7_II.gif") } // None...
  &.class-7_III { background-image: url("images/class_7_III.gif") } // None...
  &.class-8 { background-image: url("images/class_8.gif") } // UN2800
  &.class-9 { background-image: url("images/class_9.jpg") } // UN3548

  // UN1051 is a definite marine pollutant:
  &.marine-pollutant { background-image: url("images/marine.jpg") }

  // UN0132 is a potential marine pollutant:
  &.marine-pollutant-maybe { background-image: url("images/marine_maybe.jpg") }
}
