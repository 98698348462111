.simple-dropdown {
  display: block;

  .label { display: none }

  select {
    width: 100%;
    height: 49px;

    cursor: pointer;
    display: block;
    background: #fff;
    color: #222;
    border-radius: 3px;
    border: 1px solid #cccccc;
    padding: 13px 19px;

    // Prevent the text from getting in the way of the arrow:
    padding-right: 50px;

    // Get rid of the arrow on the right-hand side:
    appearance: none;

    // Use a custom "arrow" image:
    background-image: url("dropdown.png");
    background-position: right 26px center;
    background-repeat: no-repeat;
  }

  // See https://stackoverflow.com/a/8442831/4200092
  select:invalid { color: #909090 }

  // Make it obvious when a drop-down has no selectable options:
  select:disabled {
    background: #EEE;
    color: #b0b0b0;
    cursor: not-allowed;
  }
}
