.simple-page {
  background: #F8F9FD;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  // This is the (optional) header, appearing above:
  > .header {
    margin-top: 70px;

    // HAZculator logo image:
    &::before {
      top: -14px;

      content: "";
      position: relative;
      display: block;
      min-height: 48px;

      background-image: url("../../logo.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  // This is the main page "box", which appears in the center of the screen:
  > .container {
    margin: 0 auto;
    padding: 40px;

    background: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 5px;

    &.wide { width: 960px }
    &:not(.wide) { width: 520px }

    > .title {
      display: block;
      text-align: center;
      margin-bottom: 40px;
      font-size: 26px;
      font-weight: 700;
      color: #171717;
    }

    > .content {
      // ...
    }
  }

  // This is the (optional) footer, appearing underneath:
  > .footer {
    margin-bottom: 40px;
  }
}
