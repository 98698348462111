.compatibility-line {
  display: flex;
  margin: 10px 0;
  align-items: center;

  > :not(.text) { width: 72px; }

  > .text {
    flex: 1;
    margin-left: 10px;
    margin-top: 0;
  }
}
