.promo-code-search {
  display: flex;

  .apply-promo-code {
    cursor: pointer;
    margin-top: 8px;
    margin-left: 8px;

    border: none;
    border-radius: 3px;
    color: #FFF;
    background: #4A6;
    &:focus, &:hover { filter: brightness(1.1) }
  }
}
