.success-message {
  margin: 16px 32px 16px 32px;
  padding: 15px 0;
  color: white;
  background: #12CF70;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
  line-height: 150%;
}

.simple-page .container .error-message {
  margin: 0 0 -10px 0;
  border-radius: 5px 5px 0 0;
  transform: translate(-41px, -48px);
  width: calc(100% + 82px);
  padding: 15px 0;
}
