.user-form {
  // ...
}

.edit-user-delete-button {
  > button { color: #f6131f !important }
}

.user-form-disclaimer {
  padding-top: 30px;
  margin-top: 15px;
  border-top: 2px solid #f8f9fa;
  text-align: left;
  line-height: 150%;
}
