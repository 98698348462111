.data-list {
  margin: 0;

  display: grid;
  grid-template-columns: 230px auto;

  dt, dd {
    padding-bottom: 15px;
    font-size: 13.5px;
    line-height: 150%;
  }

  dt {
    grid-column: 1;
    font-weight: bold;
    text-align: right;
    padding-right: 35px;
    border-right: 1px solid #f1f1f1;
  }

  dd {
    grid-column: 2;
    margin-left: 35px;
  }
}

.hazardous-item-list .error-message + .data-list { margin-top: 30px; }
.hazardous-item-list .generic-section .generic-section .data-list { margin-top: 0; }
