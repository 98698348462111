.hazculator-form {
  display: grid;
  row-gap: 16px;
  column-gap: 16px;
  grid-template-columns: minmax(815px, auto) minmax(400px, 600px);

  .search-form {
    grid-row: 1;
    grid-column: 1 / 3;
    margin-bottom: .6rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #f1f1f1;
  }

  .hazardous-item-list {
    grid-row: 2;
    grid-column: 1 / 2;
  }

  .compatibility-result {
    grid-row: 2;
    grid-column: 2 / 3;
    overflow-y: auto;
    position: sticky;
    top: 1.5vh;
    height: 97vh;
  }
}
