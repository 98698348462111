.hazard-icon {
  display: inline-block;
  color: #fff;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2px;
  white-space: nowrap;
  padding: 8px 9px 6px 26px;

  max-height: 30px;

  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 7px center;

  background-image: url('unknown.svg');
  background-color: #6c757d;

  &.low {
    background-image: url('check.svg');
    background-color: #12cf70;
  }

  &.medium {
    background-image: url('warning.svg');
    background-color: #ffcc4d;
  }

  &.high {
    background-image: url('warning.svg');
    background-color: #e66454;
  }

  &.limit {
    min-width: 71px;
  }
}

.hazard-icon + .hazard-icon { margin-left: 5px; }
