.hazardous-item-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;

  .item-head-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .item-head-text {
      flex-basis: 40px;
      flex-grow: 1;
      width: 60px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .item-head-buttons {
    display: flex;
    justify-content: flex-end;
  }
}
