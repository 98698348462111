.error-message {
  margin: 16px 32px 16px 32px;
  padding: 15px 0;
  color: white;
  background: #f95d65;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2em;
  line-height: 150%;
}

.simple-page .container .error-message {
  margin: 0 0 -10px 0;
  border-radius: 5px 5px 0 0;
  transform: translate(-41px, -48px);
  width: calc(100% + 82px);
  padding: 15px 0;
}

.hazardous-item-list .generic-section {
  .error-message {
    margin: 0 0 1px -20px;
    width: calc(100% + 40px);
    border-radius: 0;
    font-size: .96em;
    padding: 12px 0;
    &:first-of-type { margin-top: -20px; }
  }
}
