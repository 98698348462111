.billing-history {
  margin-top: 15px;
  .header, .items {
    .invoice-row {
      display: flex;
      padding: 16px 0;
      border-bottom: 2px solid #f5f7f8;

      .date {
        display: flex;

        .month::before, .year::before { content: '/' }

        .day { order: 1 }
        .month { order: 2 }
        .year { order: 3 }
      }

      .date, .value, .seats { flex: 1 }
      .download-invoice { min-width: 28px }
    }
  }

  .header .invoice-row { color: #939eaf }
  .items .invoice-row { border-bottom-style: dotted }
  .items .download-invoice {
    all: unset;
    cursor: pointer;
    background-image: url("./pdf_icon.svg");
    background-repeat: no-repeat;
    background-size: 28px;
    margin: -10px 0;
  }
}
