.cost-label {
  padding-bottom: 5px;
  border-bottom: 2px solid #f4f4f4;
}

.cost-row {
  font-weight: bold;
  padding: 20px 0;
  margin-bottom: 10px;

  .text-sm { margin-left: 2px;}

  .licence-count-box {
    height: 30px;
    margin-top: -0.5rem;
    background-color: #ecf0f8;
    color: #3a475a;
    border: 2px solid #dee4ef;
    border-radius: 4px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance:textfield;
    }
  }

  .base-number { font-weight: normal }

  .base-number, .licence-count-box {
    float: right;
    width: 60px;
    text-align: center;
  }
}
