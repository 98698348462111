.compatibility-context {
  .name {
    font-weight: 600;
    margin: 10px 0 0 0;
  }
  ul {
    margin: 5px 0 0 0;
    li {
      padding: 5px 0;
      line-height: 150%;
    }
  }
}
