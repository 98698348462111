.limited-toggle {
  background: none;
  color: #4c4c4c;
  border: 1px solid #dedede;
  border-top: none;
  border-bottom: none;
  margin: -1px;
  padding: 0 0 0 23px;
  min-height: 42px;
  min-width: 105px;
  cursor: pointer;

  font-size: 13.5px;
  font-weight: bold;

  background-position: 11px center;
  background-repeat: no-repeat;
  background-image: url("off.svg");
}

.on { background-image: url("on.svg") }
