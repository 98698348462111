.filter-dropdown {
  position: relative;
  .label { display: none; }

  .react-select__control {
    height: 49px;
    cursor: text;
    padding-left: 56px;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 3px;

    background-image: url("icon.png");
    background-position: left 17px center;
    background-repeat: no-repeat;

    &:not(:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .react-select__value-container {
    padding: 0;

    .react-select__single-value {
      max-width: calc(100% - (96px));
    }
  }

  .react-select__indicators { display: none; }
  .react-select__menu { margin: 0; }

  .react-select__menu-list {
    padding: 0;
    overflow: hidden;
    background: #fff;
    border: 2px solid #4D74ED;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);

    .react-select__menu-notice {
      text-align: left;
      padding: 10px;
      cursor: not-allowed;
    }

    .react-select__option {
      text-align: left;
      overflow: hidden;
      padding: 10px;
      cursor: pointer;
    }

    .react-select__option--is-focused {
      background-color: #4D74ED;
      color: #fff
    }
  }
}
