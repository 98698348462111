.simple-button {
  border-radius: 3px;
  padding: 17px 46.5px;
  cursor: pointer;
  font-weight: 700;
  transition: all .3s ease-in-out;
  outline: none;
  font-size: 17px;

  // Default theme:
  border: none;
  background: #4D74ED;
  color: #fff;

  &:hover {
    background: lighten(#4D74ED, 5%);
  }

  &.muted {
    border: none;
    background: none;
    color: #4D74ED;
    box-shadow: none;
    transition: color .3s ease-in-out;
    outline: none;
    padding: 0;
    margin-top: 30px;
    font-weight: 400;
    &:hover { color: lighten(#4D74ED, 5%); }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    box-shadow: none;
  }

  &.expand {
    width: 100%;
    margin-top: 20px;
  }

  &.warning {
   background: #f95d65;
   &:hover { background: darken(#f95d65, 15%); }
  }

  &.cancel{
   background: #BEBEBE;
   &:hover { background: darken(#BEBEBE, 15%); }
  }
}

a.simple-button {
  display: block;
  margin: 25px auto 0 auto;
  text-align: center;
  &:hover { color: white; }
}

.hazculator-form .simple-button { padding: 15px 46.5px; }
