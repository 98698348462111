.licence-count-slider {
  margin: 15px 0;
  width: 100%;

  &[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 12.3px 0;
  }
  &[type=range]:focus {
    outline: none;
  }
  &[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    background: rgba(255, 255, 255, 0);
    border-radius: 25px;
    border: 2px solid #becbe1;
  }
  &[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid #ffffff;
    height: 21px;
    width: 21px;
    border-radius: 50px;
    background: #4D74ED;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
  }
  &[type=range]:focus::-webkit-slider-runnable-track {
    background: rgba(255, 255, 255, 0);
  }
  &[type=range]::-moz-focus-outer {
    border: 0;
  }
  &[type=range]::-moz-range-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
    background: rgba(255, 255, 255, 0);
    border-radius: 25px;
    border: 2px solid #becbe1;
  }
  &[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid #ffffff;
    height: 21px;
    width: 21px;
    border-radius: 50px;
    background: #4D74ED;
    cursor: pointer;
  }
  &[type=range]::-ms-track {
    width: 100%;
    height: 7x;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &[type=range]::-ms-fill-lower {
    background: rgba(242, 242, 242, 0);
    border: 2px solid #becbe1;
    border-radius: 50px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  }
  &[type=range]::-ms-fill-upper {
    background: rgba(255, 255, 255, 0);
    border: 2px solid #becbe1;
    border-radius: 50px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  }
  &[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid #ffffff;
    height: 21px;
    width: 21px;
    border-radius: 50px;
    background: #4D74ED;
    cursor: pointer;
    height: 7px;
  }
  &[type=range]:focus::-ms-fill-lower {
    background: rgba(255, 255, 255, 0);
  }
  &[type=range]:focus::-ms-fill-upper {
    background: rgba(255, 255, 255, 0);
  }
}

.scale-end { float: right }
