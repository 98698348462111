.simple-section {

  margin: 0;
  border: none;
  padding: 0;
  & + & {
    margin-top: 35px;
    border-top: 1px solid #f0f0f0;
    padding-top: 30px;
  }

  .head {
    font-size: 24px;
    white-space: nowrap;
  }
  .body {
    margin: 16px 0;
  }
}
