.informational {
  text-align: center;
  margin-top: -25px;
  color: #a9b2c0;
}

.costs-container {
  display: flex;
  margin: 60px 40px 60px 40px;

  .configure-licences {
    flex: 60%;
  }

  .show-total {
    flex: 40%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;

    .title {
      font-weight: 500;
      font-size: 1.5em;
    }

    .total {
      font-weight: bold;
      font-size: 5em;
      display: flex;

      .cost { margin-top: -12px }

      .currency-symbol, .pennies {
        font-size: 0.4em;
        font-weight: normal;
        flex: 1;
        margin-top: -2px;
      }

      .currency-symbol {
        text-align: right;
        margin-right: 5px;
      }
      .pennies { text-align: left }
    }
    .text-sm { color: #a9b2c0; }
  }

  .text-sm { font-size: 1em }
}

.continue-to-payment {
  width: 400px;
  margin: 0 auto;
}
