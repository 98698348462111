.billing-editor {
  margin-top: 15px;

  > .admin-cost-row, > .licence-count-row, > .licence-cost-row, > .price-comparison-row {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    align-items: center;
  }

  > .admin-cost-row {
    border-bottom: 2px dotted #f8f9fa;
  }

  > .licence-count-row {
    border-bottom: 2px solid #f8f9fa;

    .licence-count-label {
      > span {
        display: block;
        margin-top: 5px;
        font-weight: 700;
      }
    }

    .licence-count-box {
      height: 30px;
      background-color: #ecf0f8;
      color: #3a475a;
      border: 2px solid #dee4ef;
      border-radius: 4px;
      width: 60px;
      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance:textfield;
      }
    }
  }

  > .licence-cost-row {
    font-weight: bold;
    display: inline-block;
    padding-bottom: 0;

    .per-licence-text { font-size: 0.7rem; }
  }

  > .price-comparison-row {
    > .current-price, > .new-price {
      display: flex;
      @media screen and (max-width: 1360px) { flex-direction: column }

      > .current-price-label, > .new-price-label {
        padding-right: 0.5em;
      }

      > .current-price-amount {
        font-weight: bold;
      }

      > .new-price-amount {
        font-size: 1.5em;
        margin-top: -6px;
      }
    }
  }

  > .coupon {
    display: flex;
    padding: 6px 8px;

    position: relative;
    top: -44px;
    margin-left: 280px;
    margin-bottom: -29px;

    border-radius: 8px;
    background: #DFD;

    > .coupon-name { flex: 1 }
    > .coupon-discount { margin-left: 16px }
  }
}
