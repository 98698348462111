.cost-breakdown {
  > .title { font-size: 24px }

  > .items > .item {
    border-top: 2px solid #f4f4f4;
    padding: 14px 0;
    display: flex;

    > .quantity { order: 2; margin-right: 12px }
    > .description { order: 1; flex: 1 }
    > .price { order: 3 }

    > .quantity:not(:empty)::after { content: ' ×' }
  }

  > .items > .licences-item {
  }

  > .items > .item:last-child {
    > .quantity { order: 1; margin-right: 5px }
    > .description { order: 2; flex: 1 }
    > .price { order: 3 }
  }

  > .coupon {
    display: flex;
    margin-top: 8px;
    padding: 6px 8px;

    border-radius: 8px;
    background: #DFD;

    > .description { margin-right: 8px }
    > .duration::before { content: '(' }
    > .duration::after { content: ')' }
    > .price { flex: 1; text-align: right }
  }

  > .total {
    font-size: 24px;
    display: flex;
    padding-top: 12px;

    > .description { flex: 1 }
    > .price { /* ... */ }
  }

  // We want to surround these two elements with a (shared) border...
  > .title, > .items {
    border: 2px solid #a9b2c0;
    padding: 16px 20px;
    border-radius: 4px;
  }

  // ...so chop the bottom off this...
  > .title {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  // ...and the top off this:
  > .items {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 0;
  }
}
