.generic-section {
  .head {
    align-items: center;
    padding-left: 30px;
    min-height: 40px;
    background-color: white;
    border: 1px solid #dedede;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color .3s ease-in-out;

    background-position: 15px center;
    background-repeat: no-repeat;
    background-image: url("collapsed.svg");

    .adr-head, .imdg-head, .clash-head {
      padding-top: 10px;
    }

    &:hover { background-color: darken(#f9f9f9, 0%); }

    &.open { background-color: #f1f1f1; }

  }

  .generic-section .head { 
    background-color: #f9f9f9; 
    border-radius: 2px;
    &:hover { background-color: darken(#f9f9f9, 4%); }
    &.open { background-color: #f1f1f1; }
  }

  // Expanded:
  .head:not(:last-child) {
    background-image: url("expanded.svg");
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-position: 12px center;
  }

  .body {
    background: #FFF;
    border: 1px solid #dedede;
    border-top: none;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 20px;
    // Prevent the "consult DGSA" block from pushing the body down.
    overflow: hidden;
  }
}

.generic-section + .generic-section { margin-top: 8px }
