.injected-payment-form {
  .fields::after {
    display: block;
    content: "";
    padding-top: 30px;

    background-image: url("../Stripe/stripe.svg");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 95px;
  }

  .label-input .StripeElement--focus { border-color: #2a95fe; outline: none; }
}
