.remove-button {
  cursor: pointer;
  padding: 0;
  border: none;

  background: none;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("icon.svg");

  margin: -14px 0;
  min-width: 48px;
  min-height: 46px;

  // Hide the text:
  font-size: 0;
}
