.logout-button {
  width: 132px;
  height: 81px;

  border: 1px solid #f2f5f6;
  border-right: none;
  border-top: none;

  color: #7f7f7f;
  cursor: pointer;

  background: #fff;
  background-image: url("power.png");
  background-position: 27px center;
  background-repeat: no-repeat;
  padding-left: 30px;

  font-size: 13px;
  font-weight: bold;

  transition: opacity .3s ease-in-out;

  &:hover { opacity: 0.6; }
}
