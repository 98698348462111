.user-list {
  margin-top: 24px;
  .users { margin-bottom: 5px }
}

.delete-options {
  display: flex;

  button {
    margin: 10px;
  }
}
