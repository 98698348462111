.sign-up-progress {
  display: flex;
  margin: 40px auto 45px auto;
  width: 520px;

  .todo, .done, .current {
    flex: 1;
    display: flex;
    flex-direction: column;

    // This is the "join the dots" line between the numbers:
    &:not(:first-child)::before {
      margin-top: 14px;
      margin-left: -45px;
      height: 2px;
      width: 90px;
      background: #8B8B8B;
      border-radius: 2px;
      // This prevents us from pushing the actual element down:
      position: absolute;
      // Some content (any content) is needed to make this element visible, so:
      content: '🤔';
      color: rgba(0, 0, 0, 0)
    }

    // This is the number:
    .stage {
      border-radius: 16px;
      width: 32px;
      height: 32px;
      padding-top: 9px;
      margin: auto;
      margin-bottom: 10px;
      text-align: center;
      font-weight: 700;
    }

    // This is the actual text:
    .label { text-align: center; }
  }

  // The numbers change colours depending on what stage we're at:
  .todo .stage { background: #8B8B8B; color: #ebeff2 }
  .current .stage { background: #0874fe; color: #fff; }
  .current .label { color: #0874fe;}
  .done .stage {
    background: #ebeff2;
    color: #8B8B8B;
    text-indent: -9999px;
    position: relative;
    &::after {
      content: '✓';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      text-indent: 0;
    }
  }
  .done .label { color: #a9b2c0; }
}
