.footer-links {
  text-align: center;
  color: #777;
  margin-top: 30px;

  a {
    margin: 0 8px;
    color: #939EAF;
    transition: color .3s ease-in-out;
    &:hover, &:focus { color: #2a95fe; }
  }
}
