.login-form {
  .create-account, .forgot-password, .get-help {
    margin-top: 16px;
    text-align: center;

    button {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      transition: opacity .3s ease-in-out;
      color: #4D74ED;
      &:hover, &:focus { opacity: .7; }
    }
  }

  .disclaimer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 10px 10px 10px;
    text-align: center;

    a {
      padding-left: 5px;
      text-align: center;
    }

    input {
      height: 15px;
      width: 15px;
      margin-left: 15px;
      cursor: pointer;
      margin-top: -3px;
    }
  }

  .forgot-password { margin-top: 48px; }
}
