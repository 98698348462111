.cancel-button {
  border: none;
  padding: none;
  background: none;
  color: #f95d65;
  cursor: pointer;
  display: block;
  margin: 16px auto;
  transition: opacity .3s ease-in-out;
}

.delete-account {
  font-weight: 600;
  &::before {
    content: '';
    width: 21px;
    height: 21px;
    display: inline-block;
    vertical-align: bottom;
    background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIj48Zz48Zz4KCTxnPgoJCTxnPgoJCQk8cG9seWdvbiBwb2ludHM9IjM1My41NzQsMTc2LjUyNiAzMTMuNDk2LDE3NS4wNTYgMzA0LjgwNyw0MTIuMzQgMzQ0Ljg4NSw0MTMuODA0ICAgICIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRjk1RDY1IiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wb2x5Z29uPgoJCQk8cmVjdCB4PSIyMzUuOTQ4IiB5PSIxNzUuNzkxIiB3aWR0aD0iNDAuMTA0IiBoZWlnaHQ9IjIzNy4yODUiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6I0Y5NUQ2NSIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiPjwvcmVjdD4KCQkJPHBvbHlnb24gcG9pbnRzPSIyMDcuMTg2LDQxMi4zMzQgMTk4LjQ5NywxNzUuMDQ5IDE1OC40MTksMTc2LjUyIDE2Ny4xMDksNDEzLjgwNCAgICAiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgc3R5bGU9ImZpbGw6I0Y5NUQ2NSIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiPjwvcG9seWdvbj4KCQkJPHBhdGggZD0iTTE3LjM3OSw3Ni44Njd2NDAuMTA0aDQxLjc4OUw5Mi4zMiw0OTMuNzA2QzkzLjIyOSw1MDQuMDU5LDEwMS44OTksNTEyLDExMi4yOTIsNTEyaDI4Ni43NCAgICAgYzEwLjM5NCwwLDE5LjA3LTcuOTQ3LDE5Ljk3Mi0xOC4zMDFsMzMuMTUzLTM3Ni43MjhoNDIuNDY0Vjc2Ljg2N0gxNy4zNzl6IE0zODAuNjY1LDQ3MS44OTZIMTMwLjY1NEw5OS40MjYsMTE2Ljk3MWgzMTIuNDc0ICAgICBMMzgwLjY2NSw0NzEuODk2eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRjk1RDY1IiBkYXRhLW9sZF9jb2xvcj0iIzAwMDAwMCI+PC9wYXRoPgoJCTwvZz4KCTwvZz4KPC9nPjxnPgoJPGc+CgkJPHBhdGggZD0iTTMyMS41MDQsMEgxOTAuNDk2Yy0xOC40MjgsMC0zMy40MiwxNC45OTItMzMuNDIsMzMuNDJ2NjMuNDk5aDQwLjEwNFY0MC4xMDRoMTE3LjY0djU2LjgxNWg0MC4xMDRWMzMuNDIgICAgQzM1NC45MjQsMTQuOTkyLDMzOS45MzIsMCwzMjEuNTA0LDB6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNGOTVENjUiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIj48L3BhdGg+Cgk8L2c+CjwvZz48L2c+IDwvc3ZnPg==');
    background-size: 21px;
    background-repeat: no-repeat;
    margin-right: 10px;
  }
  &:hover { opacity: 0.75; }
}

.delete-options {
  display: flex;

  button {
    margin: 10px;
  }
}

.cancel-message {
  cursor: not-allowed;
  text-align: center;
}
