.create-organisation {
  .terms-and-questions-text {
    margin: 45px 0 0 0;
    line-height: 26px;
    transition: color .3s ease-in-out;
    text-align: center;
    color: #939EAF;

    a { color: #4D74ED;
      &:hover, &:focus { color: darken(#4D74ED, 15%); }
    }
  }
}
