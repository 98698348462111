.compatibility-details {
  margin-top: 22px;
  border-top: 1px dashed #e4e4e4;
  padding-top: 18px;

  > .title {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 16px;
  }
}
