.settings-page {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  max-width: 1920px;
  margin: 0 auto;

  // Columns (NOT including the "loading..." placeholder).
  > .general, > .users, > .invoices {
    background: #fff;
    border: 1px solid #f7f8f9;
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    padding: 32px;
    flex: 1;
    max-width: 40%;

    &:not(:last-child) { margin-left: 32px }
  }

  > .users {
    position: relative;
    .licences {
      position: absolute;
      top: 27px;
      right: 32px;
      &.no-licences {
        background: #f95d65;
        border-radius: 5px;
        padding: 10px 15px;
        color: #fff;
      }
      &.licences-available {
        background: #e4e4e4;
        border-radius: 5px;
        padding: 10px 15px;
        color: #3A475A;
      }
    }
  }

  > .billing-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 40%;
    margin-left: 32px;
    padding: 32px;
  }

  > .billing-container > .billing {
    background: #fff;
    border: 1px solid #f7f8f9;
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    padding: 32px;
    margin: -32px;
    flex: 1;
    &:not(:last-child) { margin-bottom: 64px }

    .body { margin: 0; }
    small {
      text-align: left;
      line-height: 150%;
      margin-top: 5px;
    }

    .info-box { margin: 30px 0;}

    .licence-links {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;

      .muted { margin-top: 0; }
    }

    .subscription-form small { margin-top: 25px; }
  }

  .invoices {
    .head {
      text-align: center;
    }

    .invoice-back-button {
      display: block;
      max-width: 300px;
      margin: 30px auto 0 auto;
    }
  }
}
