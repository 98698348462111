.info-box:not(:empty) {
  border-left: 4px solid #4d74ed;
  color: #4d74ed;
  padding: 5px 16px;
  margin-bottom: 16px;
  line-height: 20px;
  text-align: left;
}

.injected-payment-form .info-box:not(:empty) {
  margin-bottom: 36px;
}
