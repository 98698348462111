.version-form {
  display: flex;

  .on, .off {
    cursor: pointer;
    background: none;
    border: none;
    margin: 0 20px;
    font-size: 14px;
    padding: 2px 0;
    padding-left: 30px;
    user-select: none;
    background-position: 0 center;
    background-repeat: no-repeat;
    background-image: url("off.png");
  }

  .on { background-image: url("on.png") }
}
