.invoice-info {
  .logo {
    background-image: url("../../../logo.png");
    background-repeat: no-repeat;
    background-size: 154px 29px;
    height: 31px;
  }

  .text {
    margin: 16px auto;
    padding: 8px 0;

    &.small {
      font-size: 12px;
      margin-top: 70px;
    }

    div { margin: 6px auto }
  }

  .table {
    .header, .invoice-data {
      .invoice-row {
        display: flex;
        padding: 16px 0;
        border-bottom: 2px solid #f5f7f8;

        .date {
          display: flex;

          .month::before, .year::before {
            content: '/';
            padding: 3px;
          }

          .day { order: 1 }
          .month { order: 2 }
          .year { order: 3 }
        }

        .date, .value, .licences, .status, .payment-method { flex: 1 }
        .payment-method { text-align: right }
      }
    }

    .header .invoice-row { color: #939eaf }
    .invoice-data .invoice-row { border-bottom-style: dotted }
  }
}
