.label-input {
  display: block;
  width: 100%;
  margin-bottom: .5rem;

  .label {
    display: block;
    margin-top: 10px;
    color: #8B8B8B;
  }

  &.required .label::after {
    content: " (required)"
  }

  .input {
    display: block;
    width: 100%;
    background: #fff;
    color: #222;
    border-radius: 3px;
    border: 2px solid #e4e4e4;
    padding: 15.5px 18px;
    margin-top: 8px;
    transition: border-color .3s ease-in-out;
    &:disabled {
      background: #EEE;
      color: #b0b0b0;
    }
    &:focus {
      border-color: #4D74ED;
      outline: none;
    }
  }
}

.login-form .label-input {
  margin-bottom: 1.5rem;
  &:last-of-type { margin-bottom: 0; }
}

::-webkit-input-placeholder { color: #ccc; }
::-moz-placeholder { color: #ccc; }
:-ms-input-placeholder { color: #ccc; }
:-moz-placeholder { color: #ccc; }
