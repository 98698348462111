@import url('https://fonts.googleapis.com/css2?family=Chivo&display=swap');

* { box-sizing: border-box }

body {
  background: #f8f9fd;
  font: 14px 'Chivo', sans-serif;
  color: #3A475A;
}

body * { font-family: inherit }

a {
  text-decoration: none;
  color: #4D74ED;
  &:hover, &:focus { opacity: .7; }
}

.text-center { text-align: center; }

small {
  display: block;
  margin: 10px 0;
  font-size: 12px;
  text-align: justify;
}
