.user-info {
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background: #fff;
  padding: 16px;

  margin: 0;
  & + & { margin-top: 16px; }

  display: grid;
  grid-template-columns: 48px auto 80px;
  column-gap: 16px;

  .avatar {
    grid-row: 1 / 3;
    grid-column: 1;

    color: #fff;
    background: #00C2B5;
    border-radius: 100%;

    width: 48px;
    height: 48px;
    padding-top: 15px;

    font-size: 15px;
    font-weight: bold;
    text-align: center;
    align-self: center;
  }

  .name {
    grid-row: 1;
    grid-column: 2;
    align-self: center;

    font-size: 15px;
    color: #3a475a;
  }

  .email {
    grid-row: 2;
    grid-column: 2;
    align-self: center;

    font-size: 13px;
    color: #b6bdc8;
  }

  .cancellation-message {
    grid-row: 3;
    grid-column: 2/4;
    padding: 10px 0;
    color: #f95d65;
    font-weight: bold;
  }

  .actions {
    position: relative;
    grid-row: 1 / 3;
    grid-column: 3;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    &.hidden {
      .inner-actions { display: none }
    }

    &.visible {
      .options {
        background-color: #ebeff2;
        border-radius: 50%;
        height: 30px;
        width: 30px;
      }
    }

    .options {
      color: #4D74ED;
      position: absolute;
      right: 0;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      cursor: pointer;
      font-size: 1.5rem;
      text-align: center;

      -webkit-user-select: none; /* Safari */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* IE10+/Edge */
      user-select: none; /* Standard */
    }

    .inner-actions {
      position: absolute;
      top: 22px;
      right: 20px;
      background: white;
      display: flex;
      width: 150px;
      box-shadow: 0 10px 15px rgba(0,0,0,0.06);
      border: 1px solid rgba(0,0,0,0.06);
      flex-direction: column;
      z-index: 1;
      border-radius: 5px;
      overflow: hidden;

      .update, .delete {
        border: none;
        padding: 10px 1rem 10px 10px;
        margin: 0;
        background-color: transparent;
        cursor: pointer;
        transition: opacity .3s ease-in-out;
        font-weight: 400;
        text-align: left;
        outline: none;
        transition: all .3s ease-in-out;
      }

      .update {
        border-bottom: 1px solid rgba(0,0,0,0.06) !important;
        &:hover {
          background: #f7f7f7;
        }
      }
      .delete {
        color: #f95d65;
        &:hover {
          background: #f95d65;
          color: #fff;
        }
      }
    }
  }
}
